import React, { FormEvent, FunctionComponent, useState, useEffect } from 'react'
import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import LayoutModal from 'component/Layouts/LayoutModal'
import InputText from 'component/Inputs/InputText'
import Button from 'component/Button'
// import Tabs from 'component/Tabs'
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_IBAN } from 'utils/payment/constants'
import {
  FIRSTNAME,
  LASTNAME,
  // CARD,
  // IBAN,
  CREDIT_OR_DEBIT_CARD,
} from 'constants/i18n/en/common.json'
import { getPaymentById, postPaymentMethodId } from './api'
import { useSelector } from 'react-redux'
import { REQUIRED_FIELDS_ARE_MARKED_WITH_AN_ASTERISK } from 'constants/i18n/en/common.json'
import EventEmitter, {
  UPDATE_EVENT_CONTRACT,
  UPDATE_EVENT_PAYMENT_METHODS,
} from 'utils/events'
import { UPDATE_EVENT_PAYMENT } from 'utils/events'
import InputCheckbox from 'component/Inputs/InputCheckbox'
import {
  PAY,
  REGISTER_PAYMENT_METHOD,
} from 'utils/modals/modalAddPaymentMethod/constants'
import { ROLE_FAMILY } from 'utils/roles/constants'

interface IModalAddPaymentMethodProps {
  title: string
  onRequestClose: () => void
  onlyCard?: boolean
  idPayment?: string
  replacePaymentMethod?: boolean
  deleteOldPaymentMethod?: Function
  purpose: typeof PAY | typeof REGISTER_PAYMENT_METHOD
}

const ModalAddPaymentMethod: FunctionComponent<IModalAddPaymentMethodProps> = ({
  title,
  onRequestClose,
  onlyCard,
  idPayment,
  replacePaymentMethod = false,
  deleteOldPaymentMethod,
  purpose = REGISTER_PAYMENT_METHOD,
}) => {
  const [error, setError] = useState(null)
  const stripe: any = useStripe()
  const elements: any = useElements()
  const [type, setType] = useState(PAYMENT_TYPE_CARD)
  const [complete, setComplete] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [rememberCard, setRememberCard] = useState(true)
  const email = useSelector((state: IUserObj) => state.user.email)
  const role = useSelector((state: IUserObj) => state.user.accountType)
  const [loading, setLoading] = useState(false)

  const [payment, setPayement] = useState<IPaymentsProps>()

  useEffect(() => {
    if (idPayment && !replacePaymentMethod) {
      getPaymentById(idPayment, setPayement)
    }
  }, [idPayment, replacePaymentMethod])

  const handleChange = (event: any, type: string) => {
    setType(type)

    if (event.complete) {
      setComplete(true)
      setError(null)
    } else {
      setLoading(false)
      setComplete(false)
      setError(event.error.message)
    }
  }

  // Event handlers
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (loading) {
      return
    }

    const card = elements.getElement(CardElement)
    const iban = elements.getElement(IbanElement)

    if (complete) {
      setLoading(true)
    } else {
      return
    }

    const billingDetails = {
      name: `${firstName} ${lastName}`,
      email: email,
    }

    const isCard = type === PAYMENT_TYPE_CARD

    if (!onlyCard) {
      // create payment method IBAN or CARD
      stripe
        .createPaymentMethod({
          type: isCard ? PAYMENT_TYPE_CARD : PAYMENT_TYPE_IBAN,
          card: isCard ? card : null,
          sepa_debit: !isCard ? iban : null,
          billing_details: billingDetails,
        })
        .then((result: any) => {
          if (result.error) {
            // Show error to customer
            setError(result.error.message)
          } else {
            postPaymentMethodId(
              result.paymentMethod.id,
              setLoading,
              onRequestClose,
              () =>
                replacePaymentMethod &&
                deleteOldPaymentMethod &&
                deleteOldPaymentMethod()
            )
          }
        })
    } else {
      const clientSecret = payment?.stripePaymentIntent.client_secret

      // submit a payment with card only
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: card,
            billing_details: billingDetails,
          },
          setup_future_usage: 'off_session',
        })
        .then((result: any) => {
          if (result.error) {
            // Show error to customer
            setError(result.error.message)
          } else if (result.paymentMethod && rememberCard) {
            postPaymentMethodId(
              result.paymentMethod.id,
              setLoading,
              onRequestClose
            )
          } else {
            setTimeout(() => {
              setLoading(false)
              EventEmitter.dispatch(UPDATE_EVENT_PAYMENT, true)
              EventEmitter.dispatch(UPDATE_EVENT_PAYMENT_METHODS, true)
              EventEmitter.dispatch(UPDATE_EVENT_CONTRACT, true)
              onRequestClose()
            }, 1500)
          }
        })
    }
  }

  const cardSection = (
    <>
      <label
        htmlFor="card-element"
        className="control-label label-required mb-2"
      >
        {CREDIT_OR_DEBIT_CARD}
      </label>
      <CardElement onChange={(e) => handleChange(e, PAYMENT_TYPE_CARD)} />
    </>
  )

  // const ibanSection = (
  //   <>
  //     <label
  //       htmlFor="card-element"
  //       className="control-label label-required mb-2"
  //     >
  //       {IBAN}
  //     </label>
  //     <IbanElement
  //       onChange={(e) => handleChange(e, PAYMENT_TYPE_IBAN)}
  //       options={{
  //         supportedCountries: ['SEPA'],
  //         placeholderCountry: 'US',
  //       }}
  //     />
  //   </>
  // )

  return (
    <LayoutModal onRequestClose={onRequestClose} title={title}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row form-row">
          <div className="col-sm-6">
            <InputText
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              required
              value={firstName}
              label={FIRSTNAME}
              name="firstName"
            />
          </div>
          <div className="col-sm-6">
            <InputText
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              required
              value={lastName}
              label={LASTNAME}
              name="lastName"
            />
          </div>
        </div>
        {/* {!onlyCard ? (
          <Tabs
            tabsData={[
              {
                title: CARD,
                content: cardSection,
              },
              {
                title: IBAN,
                content: ibanSection,
              },
            ]}
          />
        ) : (
          cardSection
        )} */}
        {cardSection}
        <div
          className="card-errors mt-2 invalid-feedback btn-block"
          role="alert"
        >
          {error}
        </div>
        {role === ROLE_FAMILY && purpose === PAY && (
          <InputCheckbox
            checked={rememberCard}
            onChange={(value) => {
              setRememberCard(value)
            }}
            label="Remember card ?"
          />
        )}
        <p className="required-hint">
          {REQUIRED_FIELDS_ARE_MARKED_WITH_AN_ASTERISK}
        </p>
        <Button
          text="Submit"
          className="btn-primary btn-block"
          loading={loading}
          submit
        />
      </form>
    </LayoutModal>
  )
}

export default ModalAddPaymentMethod
