// import {
//   HOME_URL,
//   ACCOUNT_URL,
//   MISSIONS_URL,
//   PAYMENTS_URL,
//   NANNIES_URL,
//   INVOICES_URL,
// } from 'utils/router/constants'

import {
  HOME,
  // ACCOUNT,
  // NANNIES,
  // INVOICES,
} from 'constants/i18n/en/sidebar.json'

export const NANNY_SIDEBAR_LINKS = [
  {
    text: HOME,
    href: '/',
  },
  // {
  //   text: ACCOUNT,
  //   href: '/account',
  // },
  // {
  //   text: MISSIONS,
  //   href: '/missions',
  // },
  // {
  //   text: PAYMENTS,
  //   href: '/payments',
  // },
]

export const PARENT_SIDEBAR_LINKS = [
  {
    text: HOME,
    href: '/',
  },
  // {
  //   text: ACCOUNT,
  //   href: '/account',
  // },
  // {
  //   text: NANNIES,
  //   href: '/nannies',
  // },
  // {
  //   text: INVOICES,
  //   href: '/invoices',
  // },
]
