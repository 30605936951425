export const BASE_URL = process.env.REACT_APP_API

export const API_LOGIN_URL = `${BASE_URL}login`
export const API_ME = `${BASE_URL}me`
export const API_ME_CONTRACTS = `${API_ME}/contracts`
export const API_ME_PAYMENTS = `${API_ME}/payments`
export const API_ME_SOWS = `${API_ME}/sow`
export const API_ME_PAYMENTS_METHODS = `${API_ME}/paymentmethods`

export const GRAVATAR_URL = `https://gravatar.com/avatar`
