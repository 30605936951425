import React from 'react'

interface ISectionCardProps {
  title: string
  children: React.ReactNode
}

function SectionCard(props: ISectionCardProps) {
  const { title, children } = props

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title d-flex justify-content-between">
          <span>{title}</span>
        </h5>
        {children}
      </div>
    </div>
  )
}

export default SectionCard
