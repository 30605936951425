import React from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { decodeToken } from 'react-jwt'
import { customFetch } from 'api/helpers'
import { API_ME } from 'api/constants'
import { LOGIN_URL } from 'utils/router/constants'
import { USER_UPDATE_INFOS } from 'store/constants/user'

interface MiddlewareProps {
  children: React.ReactNode
}

function Middleware(props: MiddlewareProps) {
  const { children } = props
  const dispatch = useDispatch()
  const user = useSelector((state: IUserObj) => state.user)
  const history = useHistory()

  const loginUrl = history.location.pathname === LOGIN_URL

  const getUserInfos = () => {
    if (!user.connected) {
      return customFetch
        .get(API_ME)
        .then((response) => response.json())
        .then((user) => {
          if (user.message) {
            history.push(LOGIN_URL)
          } else {
            const token = localStorage.getItem('token')
            let decodedToken = decodeToken(token || '')

            // add more info for user state
            decodedToken.infos = user

            dispatch({
              type: USER_UPDATE_INFOS,
              user: decodedToken,
            })

            return <>{children}</>
          }
        })
        .catch(() => history.push(LOGIN_URL))
    }
  }

  if (user.connected || loginUrl) {
    return <>{children}</>
  } else {
    getUserInfos()
  }

  return <></>
}

export default Middleware
