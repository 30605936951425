import React, { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import Button from 'component/Button'
import ModalAddPaymentMethod from 'component/Modals/ModalAddPaymentMethod'
import { getPaymentById } from 'component/Modals/ModalAddPaymentMethod/api'
import EventEmitter, { UPDATE_EVENT_CONTRACT } from 'utils/events'
import { checkCurrentMonth, formatDateLocale, getMoneySymbol } from 'utils'
import { UPDATE_EVENT_PAYMENT } from 'utils/events'
import { ModalConsumer } from 'utils/hooks/ModalBehavior'
import { getPaymentStatus, getPaymentType } from 'utils/status'
import { STATUS_PAID } from 'utils/status/constants'
import { PAY } from 'utils/modals/modalAddPaymentMethod/constants'

interface IRowPaymentProps {
  payment: IPaymentsProps
}

function RowPayment(props: IRowPaymentProps) {
  const { payment } = props
  const stripe: any = useStripe()
  const [loading, setLoading] = useState(false)

  const paymentMethod = useSelector(
    (state: IUserObj) => state.user.paymentMethod[0]
  )

  const onClickPay = (payment: IPaymentsProps, showModal: Function) => {
    if (!paymentMethod) {
      return showModal(ModalAddPaymentMethod, {
        title: `Pay ${getPaymentType(
          payment.paymentType
        ).toLowerCase()} : ${getMoneySymbol(payment.currency)}${
          payment.amount
        }`,
        idPayment: payment.id,
        onlyCard: true,
        purpose: PAY,
      })
    } else {
      if (loading) {
        return
      }

      setLoading(true)

      getPaymentById(
        payment.id.toString(),
        () => {},
        (currentPayment: any) => {
          // check if client stripePaymentIntent is available
          if (!currentPayment?.stripePaymentIntent) {
            setLoading(false)
            return null
          }

          const clientSecret = currentPayment?.stripePaymentIntent.client_secret

          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: paymentMethod.id,
            })
            .then(() => {
              setTimeout(() => {
                setLoading(false)
                EventEmitter.dispatch(UPDATE_EVENT_PAYMENT, true)
                EventEmitter.dispatch(UPDATE_EVENT_CONTRACT, true)
              }, 1500)
            })
            .catch((error: any) => {
              setLoading(false)
              console.log('Failed confirm card : ' + error)
            })
        }
      )
    }
  }

  return (
    <tr>
      <td>
        {payment.paymentDueDate
          ? formatDateLocale(payment.paymentDueDate)
          : '-'}
      </td>
      <td>{getPaymentType(payment.paymentType)}</td>
      <td>
        {getMoneySymbol(payment.currency)} {payment.amount}
      </td>
      <td>{getPaymentStatus(payment.status)}</td>
      {checkCurrentMonth(payment.paymentDueDate) &&
      payment.status !== STATUS_PAID ? (
        <td>
          <ModalConsumer>
            {({ showModal }) => (
              <Button
                text="Pay"
                className="btn-block btn-outline-success text-success"
                onClick={() => onClickPay(payment, showModal)}
                loading={loading}
              />
            )}
          </ModalConsumer>
        </td>
      ) : (
        <td>
          {payment.stripePaymentAt
            ? formatDateLocale(payment.stripePaymentAt)
            : '-'}
        </td>
      )}
    </tr>
  )
}

export default RowPayment
