import React from 'react'

interface IPageHeaderProps {
  children: React.ReactNode
}

function PageHeader(props: IPageHeaderProps) {
  return (
    <div className="page-header">
      <div className="row">
        <div className="col-12">{props.children}</div>
      </div>
    </div>
  )
}

export default PageHeader
