import Home from 'page/Home'
import Login from 'page/Login'

export const HOME_URL = '/'
export const LOGIN_URL = '/login'
export const ACCOUNT_URL = '/account'
export const MISSIONS_URL = '/missions'
export const PAYMENT_URL = '/payment'
export const NANNIES_URL = '/nannies'
export const INVOICES_URL = '/invoices'

export const ROUTES = [
  {
    page: Home,
    path: HOME_URL,
  },
  {
    page: Login,
    path: LOGIN_URL,
  },
]
