import { GRAVATAR_URL } from 'api/constants'
import { createHash } from 'crypto'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

function Avatar() {
  // Getting data from store
  const userEmail = useSelector((state: IUserObj) => state.user.email)

  //   Initializing refs
  let isMounted = useRef<boolean | null>(null)

  // Initialazing local state
  const [profilePicture, setProfilePicture] = useState(
    process.env.PUBLIC_URL + '/static/images/placeholder.svg'
  )

  // Fetch profile picture
  useEffect(() => {
    isMounted.current = true
    const md5Email = createHash('md5')
      .update(userEmail.trim().toLowerCase())
      .digest('hex')

    fetch(`${GRAVATAR_URL}/${md5Email}?d=404`)
      .then((res) => {
        if (res.ok && isMounted.current) {
          setProfilePicture(res.url)
        }
      })
      .catch((err) => console.log(err))

    return () => {
      isMounted.current = false
    }
  }, [userEmail])

  return (
    // eslint-disable-next-line jsx-a11y/img-redundant-alt
    <img
      className="rounded-circle"
      src={profilePicture}
      width="40"
      alt="user profile picture"
    />
  )
}

export default Avatar
