import {
  FIRSTNAME,
  LASTNAME,
  ZIPCODE,
  BIRTHDATE,
  NATIONALITY,
  ADDRESS,
  CITY,
  PHONE,
  STATE,
  HOME,
  PARENT,
  CONTACT,
  FULLNAME,
  EMAIL,
  ENTER_OTHER_CONTACT,
} from 'constants/i18n/en/user.json'
import {
  DATE_PATTERN,
  PHONE_PATTERN,
  ZIPCODE_PATTERN,
} from 'utils/inputs/constants'

interface IModalModalUserInput {
  dom: 'input'
  label: string
  parent?: 'parent1' | 'parent2' | 'contact'
  name: keyof IUserState['infos'] | keyof IContactState | keyof IParentState
  type: string
  width: 'full' | 'half'
  disabled?: boolean
  pattern?: string
  required?: boolean
}

interface IModalUserTitle {
  dom: 'title'
  title: string
  hint?: string
}

export const MODAL_NANNY_UPDATE_FORM: Array<
  IModalUserTitle | IModalModalUserInput
> = [
  {
    dom: 'input',
    label: FIRSTNAME,
    name: 'first_name',
    type: 'text',
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: LASTNAME,
    name: 'last_name',
    type: 'text',
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: NATIONALITY,
    name: 'nationality',
    type: 'text',
    width: 'full',
    disabled: true,
  },
  {
    dom: 'input',
    label: BIRTHDATE,
    name: 'birth_date',
    type: 'date',
    pattern: DATE_PATTERN,
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: PHONE,
    pattern: PHONE_PATTERN,
    name: 'phone',
    type: 'tel',
    width: 'half',
  },
  {
    dom: 'title',
    title: HOME,
  },
  {
    dom: 'input',
    label: ADDRESS,
    name: 'address',
    type: 'text',
    width: 'full',
  },
  {
    dom: 'input',
    label: CITY,
    name: 'city',
    type: 'text',
    width: 'half',
  },
  {
    dom: 'input',
    label: ZIPCODE,
    name: 'zip_code',
    pattern: ZIPCODE_PATTERN,
    type: 'text',
    width: 'half',
  },
  {
    dom: 'input',
    label: STATE,
    name: 'state',
    type: 'text',
    width: 'full',
  },
]

export const MODAL_PARENT_UPDATE_FORM: Array<
  IModalUserTitle | IModalModalUserInput
> = [
  {
    dom: 'input',
    label: LASTNAME,
    name: 'last_name',
    required: true,
    type: 'text',
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: PHONE,
    name: 'phone',
    pattern: PHONE_PATTERN,
    type: 'tel',
    width: 'half',
  },
  {
    dom: 'title',
    title: `${PARENT} 1`,
  },
  {
    dom: 'input',
    label: FIRSTNAME,
    parent: 'parent1',
    name: 'first_name',
    type: 'text',
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: BIRTHDATE,
    parent: 'parent1',
    name: 'birth_date',
    type: 'date',
    pattern: DATE_PATTERN,
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: NATIONALITY,
    parent: 'parent1',
    name: 'nationality',
    type: 'text',
    width: 'full',
    disabled: true,
  },
  {
    dom: 'title',
    title: `${PARENT} 2`,
  },
  {
    dom: 'input',
    label: FIRSTNAME,
    parent: 'parent2',
    name: 'first_name',
    type: 'text',
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: BIRTHDATE,
    parent: 'parent2',
    name: 'birth_date',
    type: 'date',
    pattern: DATE_PATTERN,
    width: 'half',
    disabled: true,
  },
  {
    dom: 'input',
    label: NATIONALITY,
    parent: 'parent2',
    name: 'nationality',
    type: 'text',
    width: 'full',
    disabled: true,
  },
  {
    dom: 'title',
    title: CONTACT,
    hint: ENTER_OTHER_CONTACT,
  },
  {
    dom: 'input',
    label: FULLNAME,
    parent: 'contact',
    name: 'name',
    type: 'text',
    width: 'half',
    required: false,
  },
  {
    dom: 'input',
    label: PHONE,
    parent: 'contact',
    name: 'phone',
    type: 'tel',
    pattern: PHONE_PATTERN,
    width: 'half',
    required: false,
  },
  {
    dom: 'input',
    label: EMAIL,
    parent: 'contact',
    name: 'email',
    type: 'email',
    width: 'full',
    required: false,
  },
  {
    dom: 'title',
    title: HOME,
  },
  {
    dom: 'input',
    label: ADDRESS,
    name: 'address',
    type: 'text',
    width: 'full',
  },
  {
    dom: 'input',
    label: CITY,
    name: 'city',
    type: 'text',
    width: 'half',
  },
  {
    dom: 'input',
    label: ZIPCODE,
    name: 'zip_code',
    pattern: ZIPCODE_PATTERN,
    type: 'number',
    width: 'half',
  },
  {
    dom: 'input',
    label: STATE,
    name: 'state',
    type: 'text',
    width: 'full',
  },
]
