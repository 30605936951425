export const UPDATE_EVENT_PAYMENT_METHODS = 'UPDATE_EVENT_PAYMENT_METHODS'
export const UPDATE_EVENT_PAYMENT = 'UPDATE_EVENT_PAYMENT'
export const UPDATE_EVENT_CONTRACT = 'UPDATE_EVENT_CONTRACT'

class EventEmitter {
  events: any

  constructor() {
    this.events = {
      UPDATE_EVENT_PAYMENT_METHODS: [],
      UPDATE_EVENT_PAYMENT: [],
      UPDATE_EVENT_CONTRACT: [],
    }
  }

  dispatch(event: string, data: any) {
    if (!this.events[event]) return
    this.events[event].forEach((callback: Function) => callback(data))
  }

  subscribe(event: string, callback: Function) {
    if (!this.events[event]) this.events[event] = []
    this.events[event].push(callback)
  }

  remove(event: string) {
    if (this.events[event]) this.events[event] = []
  }
}

export default new EventEmitter()
