import React, { ChangeEvent, FunctionComponent } from 'react'
import './index.scss'

interface IInputCheckboxProps {
  label: string
  onChange: (value: boolean) => void
  checked: boolean
  className?: string
}

const InputCheckbox: FunctionComponent<IInputCheckboxProps> = ({
  label,
  onChange,
  checked,
  className,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange(true)
    } else {
      onChange(false)
    }
  }

  return (
    <div className={`checkbox form-group ${className || ''}`}>
      <label>
        <input
          onChange={(e) => handleInputChange(e)}
          type="checkbox"
          checked={checked}
        />{' '}
        {label}
      </label>
    </div>
  )
}

export default InputCheckbox
