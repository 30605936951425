import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

interface IButtonProps {
  text: string
  link?: string
  className?: string
  loading?: boolean
  submit?: boolean
  onClick?: (e: any) => void
  disabled?: boolean
}

const Button: FunctionComponent<IButtonProps> = ({
  text,
  link,
  className,
  loading,
  submit,
  onClick,
  disabled,
}) => {
  if (submit) {
    return (
      <button
        className={`btn ${className || ''}`}
        type="submit"
        onClick={onClick}
        disabled={disabled}
      >
        {loading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
          ></span>
        )}
        {text}
      </button>
    )
  } else {
    return (
      <Link
        className={`btn ${className || ''}`}
        to={link || '/'}
        onClick={onClick}
      >
        {loading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
          ></span>
        )}
        {text}
      </Link>
    )
  }
}

export default Button
