import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Middleware from 'api/middleware'
import { ROUTES } from 'utils/router/constants'
import { ModalProvider } from 'utils/hooks/ModalBehavior'
import ModalRoot from 'utils/hooks/ModalBehavior/modal-root'
// import LoaderTopbar from 'component/LoaderTopbar'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(STRIPE_KEY || '')

function App() {
  const routeComponents = ROUTES.map((route: any, index: number) => {
    const { path, page } = route
    return <Route exact path={path} component={page} key={index} />
  })

  return (
    <Router>
      <Elements stripe={stripePromise}>
        <ModalProvider>
          {/* <LoaderTopbar /> */}
          <ModalRoot />
          <Switch>
            <Middleware>{routeComponents}</Middleware>
          </Switch>
        </ModalProvider>
      </Elements>
    </Router>
  )
}

export default App
