import React, { Component, createContext } from 'react'

export const ModalContext = createContext({
  component: null,
  props: {},
  showModal: (component: any, props = {}) => {},
  hideModal: () => {},
})

export class ModalProvider extends Component {
  showModal = (component: any, props = {}) => {
    document.body.classList.add('modal-open')

    this.setState({
      component,
      props,
    })
  }

  hideModal = () => {
    document.body.classList.remove('modal-open')

    this.setState({
      component: null,
      props: {},
    })
  }

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal,
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export const ModalConsumer = ModalContext.Consumer
