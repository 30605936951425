import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useHistory } from 'react-router'
import { decodeToken } from 'react-jwt'
import { useDispatch } from 'react-redux'
import Button from 'component/Button'
import { customFetch } from 'api/helpers'
import { API_LOGIN_URL, API_ME } from 'api/constants'
import { USER_UPDATE_INFOS, USER_UPDATE_CONNECTED } from 'store/constants/user'
import { HOME_URL } from 'utils/router/constants'
import {
  LOGIN,
  EMAIL,
  PLACEHOLDER_EMAIL,
  PASSWORD,
  PLACEHOLDER_PASSWORD,
  // FORGOT_PASSWORD
} from 'constants/i18n/en/common.json'
import './style.scss'
import useForm from 'utils/hooks/useForm'
import InputText from 'component/Inputs/InputText'

function Login() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null)

  const handleLoginSubmit = () => {
    setLoading(true)

    customFetch
      .post(API_LOGIN_URL, inputs)
      .then((response) => {
        if (response.ok) {
          setErrorMsg(null)
          return response.json()
        } else {
          response.json().then((data) => {
            setErrorMsg(data.message)
          })
        }
      })
      .then((data) => {
        localStorage.setItem('token', data.token)

        customFetch
          .get(API_ME)
          .then((response) => response.json())
          .then((user) => {
            let decodedToken = decodeToken(data.token)

            // add more info for user state
            decodedToken.infos = user

            setLoading(false)

            dispatch({
              type: USER_UPDATE_INFOS,
              user: decodedToken,
            })
            dispatch({
              type: USER_UPDATE_CONNECTED,
              connected: true,
            })
            history.push(HOME_URL)
          })
      })
      .catch((error) => {
        setLoading(false)
        console.log('Failed login : ' + error)
      })
  }

  // Initialize form custom hook
  const { inputs, handleInputChange, handleSubmit } = useForm(
    handleLoginSubmit,
    {
      email: '',
      password: '',
    }
  )

  return (
    <div className="main-wrapper">
      <div className="login-page">
        <div className="login-body container">
          <div className="loginbox">
            <div className="login-right-wrap">
              <div className="account-header">
                <div className="account-logo text-center mb-4">
                  <a href="index.html">
                    <img
                      src={process.env.PUBLIC_URL + '/static/images/logo.svg'}
                      alt="Logo Whizzkid"
                      className="img-fluid"
                    />
                  </a>
                </div>

                <div className="text-center mb-4">
                  Nanny and parent access
                </div>
              </div>

              <form onSubmit={(e) => handleSubmit(e)}>
                <InputText
                  onChange={(
                    e: ChangeEvent<HTMLInputElement>,
                    setErrorMessage?: Dispatch<SetStateAction<string>>
                  ) => handleInputChange(e, setIsFormValid, setErrorMessage)}
                  type="email"
                  required={true}
                  label={EMAIL}
                  placeholder={PLACEHOLDER_EMAIL}
                  name="email"
                  showRequiredHint={false}
                  value={inputs['email']}
                />
                <InputText
                  onChange={(
                    e: ChangeEvent<HTMLInputElement>,
                    setErrorMessage?: Dispatch<SetStateAction<string>>
                  ) => handleInputChange(e, setIsFormValid, setErrorMessage)}
                  type="password"
                  required={true}
                  label={PASSWORD}
                  placeholder={PLACEHOLDER_PASSWORD}
                  name="password"
                  showRequiredHint={false}
                  value={inputs['password']}
                />
                <div className="text-center">
                  {errorMsg && <p className="text-danger">{errorMsg}</p>}
                  <Button
                    text={LOGIN}
                    className="btn-primary btn-block account-btn"
                    loading={loading}
                    submit
                    disabled={!isFormValid}
                  />
                </div>
              </form>

              {/* <div className="text-center forgotpass mt-4">
                <a href="forgot-password.html">{FORGOT_PASSWORD}</a>
              </div> */}
              {/* <div className="login-or">
                <span className="or-line"></span>
                <span className="span-or">or</span>
              </div>

              <div className="social-login">
                <span>Login with</span>
                <a href="#" className="facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="google">
                  <i className="fab fa-google"></i>
                </a>
              </div>
              <div className="text-center dont-have">
                Don’t have an account? <a href="register.html">Register</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
