import React from 'react'
import { PAYMENT_TYPE_CARD } from './constants'

export const formatPayment = (data: any) => {
  if (data.type === PAYMENT_TYPE_CARD) {
    const brand = data.card.brand.toUpperCase()
    const last4 = data.card.last4

    return (
      <small key={data.created} className="mr-2">
        {brand} *** - {last4}
      </small>
    )
  } else {
    const last4 = data.sepa_debit.last4
    const country = data.sepa_debit.country
    return (
      <small key={data.created}>
        IBAN - {country}XX *** {last4}
      </small>
    )
  }
}

export const formatPaymentExpire = (data: any) => {
  if (data.type === PAYMENT_TYPE_CARD) {
    const expMonth = data.card.exp_month
    const expYear = data.card.exp_year

    return (
      <small>
        {expMonth}/{expYear}
      </small>
    )
  } else {
    return <small>-</small>
  }
}
