import { API_ME_PAYMENTS, API_ME_PAYMENTS_METHODS } from 'api/constants'
import { customFetch } from 'api/helpers'
import store from 'store'
import EventEmitter, {
  UPDATE_EVENT_CONTRACT,
  UPDATE_EVENT_PAYMENT,
  UPDATE_EVENT_PAYMENT_METHODS,
} from 'utils/events'
import { ROLE_NANNY } from 'utils/roles/constants'

export const getPaymentById = (
  id: string,
  setPayement: Function,
  callback?: Function
) => {
  customFetch
    .get(`${API_ME_PAYMENTS}/${id}`)
    .then((response) => response.json())
    .then((payment) => {
      setPayement(payment)
      callback && callback(payment)
    })
    .catch((error) => console.log(`Get payment : ${error}`))
}

export const postPaymentMethodId = (
  id: string,
  setLoading: Function,
  onRequestClose: Function,
  callback?: Function
) => {
  const role = store.getState().user.accountType

  const currentCallback = () => {
    setLoading(false)
    EventEmitter.dispatch(UPDATE_EVENT_PAYMENT, true)
    EventEmitter.dispatch(UPDATE_EVENT_PAYMENT_METHODS, true)
    EventEmitter.dispatch(UPDATE_EVENT_CONTRACT, true)
    callback && callback()
    onRequestClose()
    return
  }

  if (role === ROLE_NANNY) {
    currentCallback()
  }

  customFetch
    .post(
      API_ME_PAYMENTS_METHODS,
      {
        payment_method_id: id,
      },
      true
    )
    .then((response) => {
      if (response.ok) {
        setTimeout(() => {
          currentCallback()
        }, 1500)
      }
    })
    .catch((error) => console.log('Failed login : ' + error))
}
