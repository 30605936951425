import React, { useEffect, useState } from 'react'
import Button from 'component/Button'
import {
  CONTRACT_DETAILS,
  STARTING_FROM,
  ONBOARDING_FEE,
  MEMBERSHIP_FEE,
  DOWNLOAD_CONTRACT,
  PAYMENT_STATUS,
  SIGNED_AT,
} from 'constants/i18n/en/dashboard.json'
import { customFetch } from 'api/helpers'
import { API_ME_CONTRACTS } from 'api/constants'
import { formatDateLocale, formatPrice } from 'utils'
import { getPaymentStatus } from 'utils/status'
import SectionCard from 'component/SectionCard'
import { ROLE_NANNY } from 'utils/roles/constants'
import EventEmitter, { UPDATE_EVENT_CONTRACT } from 'utils/events'

interface SectionContractDetailsProps {
  role: string
  membershipFee: string
}

function SectionContractDetails(props: SectionContractDetailsProps) {
  const [contracts, setContracts] = useState<IContractProps[]>([])
  const { role, membershipFee } = props

  useEffect(() => {
    const fetchContract = () => {
      customFetch
        .get(`${API_ME_CONTRACTS}`)
        .then((response) => response.json())
        .then((contracts) => setContracts(contracts))
        .catch((error) => console.log(`Get contracts : ${error}`))
    }

    fetchContract()
    EventEmitter.subscribe(UPDATE_EVENT_CONTRACT, () => fetchContract())

    return function cleanup() {
      EventEmitter.remove(UPDATE_EVENT_CONTRACT)
    }
  }, [])

  const downloadContract = (id: number) => {
    // const token = localStorage.getItem('token')

    // fetch(`${API_ME_CONTRACTS}/${id}/document`, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/pdf',
    //     responseType: 'blob',
    //   },
    // }).then(response => {
    //   console.log(response)
    //   return response.blob()
    // })
    // .then(blob => saveAs(blob, 'test.pdf'))

    customFetch
      .get(`${API_ME_CONTRACTS}/${id}/document`)
      .then((response: any) => response.blob())
      .then((blob: any) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = 'contract.pdf'
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error: any) => console.log(`Get document : ${error}`))
  }

  return (
    <SectionCard title={CONTRACT_DETAILS}>
      {contracts.length > 0 &&
        contracts.map((contract: IContractProps) => (
          <div key={contract.id}>
            <div className="row mt-4">
              <div className="col-sm-3">
                <p className="h6 mb-0 mb-sm-2">{STARTING_FROM}</p>
                <p>
                  {contract.startDate
                    ? formatDateLocale(contract.startDate)
                    : '-'}
                </p>
              </div>
              <div className="col-sm-3">
                <p className="h6 mb-0 mb-sm-2">{PAYMENT_STATUS}</p>
                <p>
                  {contract.status ? getPaymentStatus(contract.status) : '-'}
                </p>
              </div>
              <div className="col-sm-3">
                <p className="h6 mb-0 mb-sm-2">{SIGNED_AT}</p>
                <p>
                  {contract.signedAt
                    ? formatDateLocale(contract.signedAt)
                    : '-'}
                </p>
              </div>
              <div className="col-sm-3">
                <p className="h6 mb-0 mb-sm-2">
                  {role === ROLE_NANNY ? ONBOARDING_FEE : MEMBERSHIP_FEE}
                </p>
                <p>{formatPrice(membershipFee)}</p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <Button
                  text={DOWNLOAD_CONTRACT}
                  className="btn-primary"
                  onClick={() => downloadContract(contract.id)}
                />
              </div>
            </div>
          </div>
        ))}
    </SectionCard>
  )
}

export default SectionContractDetails
