import React, { useRef, useEffect, RefObject, FunctionComponent } from 'react'

// Hook that alerts clicks outside of the passed ref
const useDetectOutside = (ref: RefObject<any>, callback: Function) => {
  // Alert if clicked on outside of element
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

interface IDetectOutsideProps {
  callback: Function
  className?: string
  customRef?: RefObject<any>
  role?: string
}

// Component that alerts if you click outside of it
const DetectOutside: FunctionComponent<IDetectOutsideProps> = ({
  callback,
  customRef,
  className,
  children,
  role,
}) => {
  const wrapperRef = useRef(null)

  const selectedRef = customRef ? customRef : wrapperRef

  useDetectOutside(selectedRef, callback)

  return (
    <div
      className={className || undefined}
      role={role || undefined}
      ref={selectedRef}
    >
      {children}
    </div>
  )
}

export default DetectOutside
