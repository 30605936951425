import React, { FunctionComponent, useState } from 'react'
import LayoutModal from 'component/Layouts/LayoutModal'
import Button from 'component/Button'
import { formatPayment, formatPaymentExpire } from 'utils/payment'
import { customFetch } from 'api/helpers'
import { API_ME_PAYMENTS_METHODS } from 'api/constants'
import EventEmitter, { UPDATE_EVENT_PAYMENT_METHODS } from 'utils/events'
import { PAYMENT_METHOD, EXPIRE } from 'constants/i18n/en/common.json'
import { ModalConsumer } from 'utils/hooks/ModalBehavior'
import ModalAddPaymentMethod from '../ModalAddPaymentMethod'
import { REPLACE_PAYMENT_METHOD } from 'constants/i18n/en/dashboard.json'

interface IModalEditPaymentMethodProps {
  title: string
  onRequestClose: () => void
  paymentMethod: any
}

const ModalEditPaymentMethod: FunctionComponent<IModalEditPaymentMethodProps> = ({
  title,
  onRequestClose,
  paymentMethod,
}) => {
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(
    paymentMethod
  )

  const deletePaymentMethod = (id: string) => {
    customFetch
      .delete(`${API_ME_PAYMENTS_METHODS}/${id}`)
      .then((response) => {
        if (response.ok) {
          // remove current deleted payment method
          const newPaymentMethods = currentPaymentMethod.filter(
            (p: any) => p.id !== id
          )
          setCurrentPaymentMethod(newPaymentMethods)
          EventEmitter.dispatch(UPDATE_EVENT_PAYMENT_METHODS, true)

          // close modal if 0 payment methods
          if (newPaymentMethods.length === 0) {
            onRequestClose()
          }
        }
      })
      .catch((error) => console.log('Failed delete payment method : ' + error))
  }

  return (
    <LayoutModal onRequestClose={onRequestClose} title={title}>
      <div className="table-responsive">
        <table className="table table-center">
          <thead>
            <tr>
              <th className="h6">{PAYMENT_METHOD}</th>
              <th className="h6">{EXPIRE}</th>
            </tr>
          </thead>
          <tbody>
            {currentPaymentMethod.map((paymentMethod: any) => (
              <tr key={paymentMethod.id}>
                <td>{formatPayment(paymentMethod)}</td>
                <td>{formatPaymentExpire(paymentMethod)}</td>
                <td>
                  <ModalConsumer>
                    {({ showModal }) => (
                      <Button
                        text="Replace"
                        className="btn-block btn-outline-info text-info"
                        onClick={(e) => {
                          e.preventDefault()
                          showModal(ModalAddPaymentMethod, {
                            title: REPLACE_PAYMENT_METHOD,
                            idPayment: paymentMethod.id,
                            replacePaymentMethod: true,
                            deleteOldPaymentMethod: () =>
                              deletePaymentMethod(paymentMethod.id),
                          })
                        }}
                      />
                    )}
                  </ModalConsumer>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LayoutModal>
  )
}

export default ModalEditPaymentMethod
