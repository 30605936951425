import React, { useEffect, useState } from 'react'
import SectionCard from 'component/SectionCard'
import {
  PAYMENTS,
  DUE_DATE,
  TYPE,
  AMOUNT,
  STATUS,
  PAYMENT_DATE,
} from 'constants/i18n/en/dashboard.json'
import { customFetch } from 'api/helpers'
import { API_ME_PAYMENTS } from 'api/constants'
import EventEmitter, { UPDATE_EVENT_PAYMENT } from 'utils/events'
import RowPayment from './RowPayment'

function SectionPayments() {
  const [payments, setPayements] = useState<IPaymentsProps[]>([])

  useEffect(() => {
    const fetchPayment = () => {
      customFetch
        .get(`${API_ME_PAYMENTS}`)
        .then((response) => response.json())
        .then((payments) => setPayements(payments))
        .catch((error) => console.log(`Get payments : ${error}`))
    }

    fetchPayment()
    EventEmitter.subscribe(UPDATE_EVENT_PAYMENT, () => fetchPayment())

    return function cleanup() {
      EventEmitter.remove(UPDATE_EVENT_PAYMENT)
    }
  }, [])

  return (
    <SectionCard title={PAYMENTS}>
      <div className="table-responsive">
        <table className="table table-center">
          <thead>
            <tr>
              <th className="h6">{DUE_DATE}</th>
              <th className="h6">{TYPE}</th>
              <th className="h6">{AMOUNT}</th>
              <th className="h6">{STATUS}</th>
              <th className="h6">{PAYMENT_DATE}</th>
            </tr>
          </thead>
          <tbody>
            {payments.length > 0 &&
              payments.map((payment) => (
                <RowPayment key={payment.id} payment={payment} />
              ))}
          </tbody>
        </table>
      </div>
    </SectionCard>
  )
}

export default SectionPayments
