import React, {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from 'react'

interface IInputTextProps {
  name: string
  onChange: (
    e: ChangeEvent<HTMLInputElement>,
    setErrorMessage?: Dispatch<SetStateAction<string>>
  ) => void
  type: string
  value: string
  label?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  pattern?: string
  showRequiredHint?: boolean
}

const InputText: FunctionComponent<IInputTextProps> = ({
  type,
  label,
  onChange,
  disabled = false,
  placeholder,
  value,
  name,
  required,
  pattern,
  showRequiredHint = true,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')

  return (
    <div className="form-group">
      {label && (
        <label
          className={
            required && showRequiredHint && !disabled ? 'label-required' : ''
          }
        >
          {label}
        </label>
      )}
      <input
        value={value || ''}
        disabled={disabled}
        required={required}
        onChange={(e) => onChange(e, setErrorMessage)}
        pattern={pattern}
        type={type}
        placeholder={placeholder}
        className="form-control"
        name={name}
      />
      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  )
}

export default InputText
