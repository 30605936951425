import React, { useEffect, useState } from 'react'
import SectionCard from 'component/SectionCard'
import Button from 'component/Button'
import { customFetch } from 'api/helpers'
import { API_ME_SOWS } from 'api/constants'
import {
  YOUR_MISSIONS,
  YOUR_NANNIES,
  KID,
  PARENTS,
  LANGUAGE,
  HOURS,
  WEEK,
  HOURLY_RATE,
  DOWNLOAD_CONTRACT,
  REFERENCE_SALARY,
} from 'constants/i18n/en/dashboard.json'
import { NAME_W } from 'constants/i18n/en/common.json'
import { ROLE_NANNY } from 'utils/roles/constants'
import { getMoneySymbol, getReferenceSalary } from 'utils'

interface ISectionNannyMissions {
  role: string
}

function SectionNannyMissions(props: ISectionNannyMissions) {
  const { role } = props
  const [sows, setSows] = useState<Array<ISows>>([])

  const isNanny = role === ROLE_NANNY

  useEffect(() => {
    customFetch
      .get(API_ME_SOWS)
      .then((response) => response.json())
      .then((response) => setSows(response))
      .catch((err) => console.log(`Fetch sows failed : ${err}`))
  }, [])

  const downloadContract = (id: number) => {
    customFetch
      .get(`${API_ME_SOWS}/${id}/document`)
      .then((response: any) => response.blob())
      .then((blob: any) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = 'statement-of-work.pdf'
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error: any) => console.log(`Get document : ${error}`))
  }

  const renderTableHeader = () => {
    if (isNanny) {
      return (
        <tr>
          <th className="h6">{KID}(s)</th>
          <th className="h6">{PARENTS}</th>
          <th className="h6">{LANGUAGE}</th>
          <th className="h6">
            {HOURS} / {WEEK}
          </th>
          <th className="h6">{HOURLY_RATE}</th>
        </tr>
      )
    } else {
      return (
        <tr>
          <th className="h6">{NAME_W}</th>
          <th className="h6">{LANGUAGE}</th>
          <th className="h6">
            {HOURS} / {WEEK}
          </th>
          <th className="h6">{HOURLY_RATE}</th>
          <th className="h6">{REFERENCE_SALARY}</th>
        </tr>
      )
    }
  }

  const renderTableRows = () => {
    return sows?.map((sow) => {
      const parents = `${sow.family.parent1FirstName} ${
        sow.family.parent2FirstName ? `& ${sow.family.parent2FirstName}` : ''
      } ${sow.family.lastName}`

      const colButton = sow.canDownloadDocument ? (
        <td>
          <Button
            text={DOWNLOAD_CONTRACT}
            className="btn-primary text-white"
            onClick={() => downloadContract(sow.id)}
          />
        </td>
      ) : null

      if (isNanny) {
        return (
          <tr key={sow.id}>
            <td>{sow.kid.firstName}</td>
            <td>{parents}</td>
            <td>{sow.language}</td>
            <td>{sow.hoursPerWeek} hrs</td>
            <td>
              {getMoneySymbol('USD')} {sow.hourlyRate}
            </td>
            {colButton}
          </tr>
        )
      } else {
        return (
          <tr key={sow.id}>
            <td>
              {sow.nanny.firstName} {sow.nanny.lastName}
            </td>
            <td>{sow.language}</td>
            <td>{sow.hoursPerWeek} hrs</td>
            <td>
              {getMoneySymbol('USD')} {sow.hourlyRate}
            </td>
            <td>
              {getReferenceSalary(sow.hourlyRate, sow.hoursPerWeek, 'USD')}
            </td>
            {colButton}
          </tr>
        )
      }
    })
  }

  return (
    <SectionCard title={isNanny ? YOUR_MISSIONS : YOUR_NANNIES}>
      <div className="table-responsive">
        <table className="table table-center table-borderless">
          <thead>{renderTableHeader()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
    </SectionCard>
  )
}

export default SectionNannyMissions
