import Header from 'component/Header'
import Sidebar from 'component/Sidebar'
import React, { useRef } from 'react'

interface LayoutSinglePageProps {
  children: React.ReactNode
}

function LayoutSinglePage(props: LayoutSinglePageProps) {
  // Initialazing refs
  const wrapperRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  // Helper functions
  const toggleOverlayClass = (action: 'add' | 'remove') => {
    if (action === 'add') {
      overlayRef.current?.classList.add('opened')
    } else {
      overlayRef.current?.classList.remove('opened')
    }
  }

  const toggleMobileSidebarClass = (action: 'add' | 'remove') => {
    if (action === 'add') {
      wrapperRef.current?.classList.add('slide-nav')
    } else {
      wrapperRef.current?.classList.remove('slide-nav')
    }
  }

  const toggleHtmlClass = (action: 'add' | 'remove') => {
    if (action === 'add') {
      document.documentElement.classList.add('menu-opened')
    } else {
      document.documentElement.classList.remove('menu-opened')
    }
  }

  // Event handlers
  const handleSidebarOverlayClick = () => {
    toggleHtmlClass('remove')
    toggleMobileSidebarClass('remove')
    toggleOverlayClass('remove')
  }

  return (
    <>
      <div ref={wrapperRef} className="main-wrapper">
        <Header
          toggleHtmlClass={toggleHtmlClass}
          toggleOverlayClass={toggleOverlayClass}
          toggleMobileSidebarClass={toggleMobileSidebarClass}
        />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">{props.children}</div>
        </div>
      </div>
      <div
        ref={overlayRef}
        onClick={() => handleSidebarOverlayClick()}
        className="sidebar-overlay"
      ></div>
    </>
  )
}

export default LayoutSinglePage
