import { useEffect, useLayoutEffect, useRef } from 'react'

interface IPosition {
  x: number
  y: number
}

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect
const isBrowser = typeof window !== `undefined`
const zeroPosition = { x: 0, y: 0 }

const getScrollPosition = () => {
  if (!isBrowser) {
    return zeroPosition
  }

  const targetPosition = document.body.getBoundingClientRect()

  if (!targetPosition) {
    return zeroPosition
  }

  return { x: targetPosition.left, y: targetPosition.top }
}

export const useScrollPosition = (
  effect: (props: IPosition) => void,
  wait?: number
): void => {
  const position = useRef(getScrollPosition())

  let throttleTimeout: number | null = null

  const callBack = () => {
    const currPos = getScrollPosition()
    effect(currPos)
    position.current = currPos
    throttleTimeout = null
  }

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return undefined
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = window.setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)

      if (throttleTimeout) {
        clearTimeout(throttleTimeout)
      }
    }
  })
}
