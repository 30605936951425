import React from 'react'
import { useSelector } from 'react-redux'
import DashboardNanny from 'component/Dashboards/DashboardNanny'
import DashboardFamily from 'component/Dashboards/DashboardFamily'
import LayoutSinglePage from 'component/Layouts/LayoutSinglePage'
import { ROLE_NANNY, ROLE_FAMILY } from 'utils/roles/constants'

function Home() {
  const user = useSelector((state: IUserObj) => state.user)

  return (
    <LayoutSinglePage>
      <div className="page-header">
        <div className="row">
          <div className="col">
            {user.accountType === ROLE_NANNY && <DashboardNanny user={user} />}
            {user.accountType === ROLE_FAMILY && (
              <DashboardFamily user={user} />
            )}
          </div>
        </div>
      </div>
    </LayoutSinglePage>
  )
}

export default Home
