import React, { FunctionComponent } from 'react'
import DetectOutside from 'utils/hooks/DetectOutside'
import './index.scss'

interface ILayoutModalProps {
  title: string
  onRequestClose: () => void
}

const LayoutModal: FunctionComponent<ILayoutModalProps> = ({
  title,
  children,
  onRequestClose,
}) => {
  return (
    <>
      <div className="modal fade show" role="dialog">
        <DetectOutside
          callback={onRequestClose}
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onRequestClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </DetectOutside>
      </div>
      <div className="fade show modal-backdrop"></div>
    </>
  )
}

export default LayoutModal
