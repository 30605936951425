import React, { useEffect, useState } from 'react'
import Alert from 'component/Alert'
import Button from 'component/Button'
import {
  YOUR_ACCOUNT,
  ADDRESS,
  ALERT_PAYMENTS,
  PHONE,
  EMAIL,
  PAYMENT_METHOD,
  EDIT_MY_ACCOUNT,
  UPDATE_YOUR_INFORMATION,
  SAVE_CHANGES,
  EDIT_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD,
  PAYMENT_METHOD_NOT_SPECIFIED,
  SEE_PAYMENT_METHODS,
} from 'constants/i18n/en/dashboard.json'
import SectionCard from 'component/SectionCard'
import { ROLE_FAMILY } from 'utils/roles/constants'
import { ModalConsumer } from 'utils/hooks/ModalBehavior'
import ModalUserUpdate from 'component/Modals/ModalUserUpdate'
import { customFetch } from 'api/helpers'
import { API_ME_PAYMENTS_METHODS } from 'api/constants'
import ModalAddPaymentMethod from 'component/Modals/ModalAddPaymentMethod'
import ModalEditPaymentMethod from 'component/Modals/ModalEditPaymentMethod'
import EventEmitter, { UPDATE_EVENT_PAYMENT_METHODS } from 'utils/events'
import { useDispatch } from 'react-redux'
import { USER_UPDATE_PAYMENT_METHOD } from 'store/constants/user'

function SectionAccount(props: IUserObj) {
  const [paymentMethod, setPaymentMethod] = useState<any>([])
  const dispatch = useDispatch()

  const { user } = props
  const isFamily = user.accountType === ROLE_FAMILY

  useEffect(() => {
    if (isFamily) {
      const fetchPaymentMethods = () => {
        customFetch
          .get(`${API_ME_PAYMENTS_METHODS}`)
          .then((response) => response.json())
          .then((methods) => {
            dispatch({
              type: USER_UPDATE_PAYMENT_METHOD,
              paymentMethod: methods.data,
            })
            setPaymentMethod(methods.data)
          })
          .catch((error) => console.log(`Get payment method : ${error}`))
      }

      fetchPaymentMethods()
      EventEmitter.subscribe(UPDATE_EVENT_PAYMENT_METHODS, () =>
        fetchPaymentMethods()
      )

      return function cleanup() {
        EventEmitter.remove(UPDATE_EVENT_PAYMENT_METHODS)
      }
    }
  }, [isFamily, dispatch])

  return (
    <SectionCard title={YOUR_ACCOUNT}>
      {paymentMethod.length === 0 && isFamily && (
        <Alert className="mb-4">
          <strong>{ALERT_PAYMENTS}</strong>
        </Alert>
      )}
      <div className="row mt-4">
        <div className="col-sm-3">
          <p className="h6 mb-0 mb-sm-2">{ADDRESS}</p>
          <p>{user.infos.address ? user.infos.address : '-'}</p>
        </div>
        <div className="col-sm-3">
          <p className="h6 mb-0 mb-sm-2">{PHONE}</p>
          <p>{user.infos.phone ? user.infos.phone : '-'}</p>
        </div>
        <div className="col-sm-3">
          <p className="h6 mb-0 mb-sm-2">{EMAIL}</p>
          <p>{user.email !== '' ? user.email : '-'}</p>
        </div>
        {isFamily && (
          <div className="col-sm-3">
            <p className="h6 mb-0 mb-sm-2">{PAYMENT_METHOD}</p>
            <ModalConsumer>
              {({ showModal }) => (
                <p>
                  <a
                    href="/"
                    className="text-primary"
                    onClick={(e) => {
                      e.preventDefault()
                      if (paymentMethod.length === 0) {
                        showModal(ModalAddPaymentMethod, {
                          title: ADD_PAYMENT_METHOD,
                        })
                      } else {
                        return showModal(ModalEditPaymentMethod, {
                          title: EDIT_PAYMENT_METHOD,
                          paymentMethod: paymentMethod,
                        })
                      }
                    }}
                  >
                    {paymentMethod.length === 0
                      ? PAYMENT_METHOD_NOT_SPECIFIED
                      : SEE_PAYMENT_METHODS}
                  </a>
                </p>
              )}
            </ModalConsumer>
          </div>
        )}
      </div>
      {isFamily && (
        <div className="row">
          <div className="col">
            <ModalConsumer>
              {({ showModal }) => (
                <>
                  <Button
                    text={EDIT_MY_ACCOUNT}
                    className="btn-primary mr-3"
                    onClick={() =>
                      showModal(ModalUserUpdate, {
                        title: UPDATE_YOUR_INFORMATION,
                        buttonText: SAVE_CHANGES,
                      })
                    }
                  />
                  <Button
                    text={
                      paymentMethod.length === 0
                        ? ADD_PAYMENT_METHOD
                        : EDIT_PAYMENT_METHOD
                    }
                    className="btn-primary"
                    onClick={() => {
                      if (paymentMethod.length === 0) {
                        showModal(ModalAddPaymentMethod, {
                          title: ADD_PAYMENT_METHOD,
                        })
                      } else {
                        showModal(ModalEditPaymentMethod, {
                          title: EDIT_PAYMENT_METHOD,
                          paymentMethod: paymentMethod,
                        })
                      }
                    }}
                  />
                </>
              )}
            </ModalConsumer>
          </div>
        </div>
      )}
    </SectionCard>
  )
}

export default SectionAccount
