import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
} from 'react'

const useForm = (callback: Function, defaultInputs: any) => {
  const [inputs, setInputs] = useState(defaultInputs)

  const handleSubmit = (event: FormEvent) => {
    if (event) {
      event.preventDefault()
    }
    callback()
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    setIsFormValid?: Dispatch<SetStateAction<boolean>>,
    setErrorMessage?: Dispatch<SetStateAction<string>>,
    parent?: string
  ) => {
    event.persist()

    const toggleInvalidClass = (
      el: HTMLInputElement,
      action: 'add' | 'remove'
    ) => {
      if (action === 'add') {
        el.classList.add('is-invalid')
      } else {
        el.classList.remove('is-invalid')
      }
    }

    if (setIsFormValid) {
      if (event.target.form?.checkValidity()) {
        toggleInvalidClass(event.target, 'remove')
        setIsFormValid(true)
      } else {
        setIsFormValid(false)
        if (!event.target.checkValidity()) {
          toggleInvalidClass(event.target, 'add')
          if (setErrorMessage) {
            setErrorMessage(event.target.validationMessage)
          }
        } else {
          toggleInvalidClass(event.target, 'remove')
        }
      }
    }

    if (parent) {
      setInputs((inputs: any) => ({
        ...inputs,
        [parent]: {
          ...inputs[parent],
          [event.target.name]: event.target.value,
        },
      }))
    } else {
      setInputs((inputs: any) => ({
        ...inputs,
        [event.target.name]: event.target.value,
      }))
    }
  }

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  }
}

export default useForm
