import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  NANNY_SIDEBAR_LINKS,
  PARENT_SIDEBAR_LINKS,
} from 'component/Sidebar/constants'
import { useSelector } from 'react-redux'
import { ROLE_NANNY } from 'utils/roles/constants'

export interface ISidebarLink {
  text: string
  href: string
}

function Sidebar() {
  // Getting data from the store
  const accountType = useSelector((state: IUserObj) => state.user.accountType)

  // Getting current pathname
  const pathname = useLocation().pathname

  //  Render functions
  const renderSidebarLinks = () => {
    let selectedSidebarLinks: Array<ISidebarLink>

    if (accountType === ROLE_NANNY) {
      selectedSidebarLinks = NANNY_SIDEBAR_LINKS
    } else {
      selectedSidebarLinks = PARENT_SIDEBAR_LINKS
    }

    return selectedSidebarLinks.map((link, index) => (
      <li key={index} className={pathname === link.href ? 'active' : ''}>
        <Link to={link.href}>
          <i className="fas fa-columns"></i> <span>{link.text}</span>
        </Link>
      </li>
    ))
  }

  // Event handlers
  const handleMouseEnter = () => {
    if (document.body.classList.contains('mini-sidebar')) {
      document.body.classList.add('expand-menu')
    }
  }

  const handleMouseLeave = () => {
    if (document.body.classList.contains('mini-sidebar')) {
      document.body.classList.remove('expand-menu')
    }
  }

  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      className="sidebar"
      id="sidebar"
    >
      <div className="sidebar-logo mb-3">
        <Link to="/">
          <img
            src="/static/images/logo.svg"
            className="img-fluid"
            alt="Whizzkid logo"
          />
        </Link>
      </div>
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>{renderSidebarLinks()}</ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
