export const formatDateLocale = (dateString: string, config?: any) => {
  let date = new Date(dateString)

  if (date) return date.toLocaleDateString(config)

  return `Err parsing ${dateString}`
}

// const monthNames = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ]

// export const formatDateLocaleMonth = (dateString: string) => {
//   return monthNames[new Date(dateString).getMonth()]
// }

export const checkCurrentMonth = (dateString: string) => {
  const dateMonth = new Date(dateString).getMonth()
  const dateYear = new Date(dateString).getFullYear()

  const currentDateMonth = new Date().getMonth()
  const currentDateYear = new Date().getFullYear()

  return dateMonth === currentDateMonth && dateYear === currentDateYear
}

export const capitalizeFirstLetter = (string: string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

let currencySymbols: any = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
}

export const getMoneySymbol = (currencyName: string) => {
  return currencySymbols[currencyName]
}

export const formatPrice = (price: string, currency = 'USD') => {
  return `${getMoneySymbol(currency)} ${parseInt(price, 10)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export const getReferenceSalary = (
  hourlyRate: string,
  hourPerWeek: number,
  currency?: string
) => {
  const numberOfMonth = 52
  const result = parseInt(hourlyRate, 10) * hourPerWeek * numberOfMonth
  return `${currency ? getMoneySymbol(currency) : ''} ${result
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}
