import React, { useState } from 'react'

interface IAlertProps {
  children: React.ReactNode
  className?: string
}

function Alert(props: IAlertProps) {
  const { className } = props
  const [hideAlert, setHideAlert] = useState(false)

  if (!hideAlert) {
    return (
      <div
        className={`alert alert-warning alert-dismissible fade show ${className ||
          ''}`}
        role="alert"
      >
        {props.children}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => setHideAlert(true)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
  } else {
    return <></>
  }
}

export default Alert
