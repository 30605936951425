import React from 'react'
import PageHeader from 'component/PageHeader'
import SectionAccount from 'component/SectionAccount'
import SectionContractDetails from 'component/SectionContractDetails'
import SectionPayments from 'component/SectionPayments'
import SectionNannyMissions from 'component/SectionNannyMissions'

interface IDashboardNannyProps {
  user: IUserState
}

function DashboardNanny(props: IDashboardNannyProps) {
  const { user } = props

  return (
    <>
      <PageHeader>
        <h3 className="page-title">Welcome {user.infos.first_name}!</h3>
      </PageHeader>
      <SectionAccount user={user} />
      <SectionContractDetails
        role={user.accountType}
        membershipFee={user.infos.membership_fee}
      />
      <SectionNannyMissions role={user.accountType} />
      <SectionPayments />
    </>
  )
}

export default DashboardNanny
