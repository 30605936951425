class API {
  getToken = () => localStorage.getItem('token')

  get = (url: string) =>
    fetch(url, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    })

  fetchByMethod = (url: string, method: string, params: any) =>
    fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getToken()}`,
      },
      body: JSON.stringify(params),
    })

  fetchByMethodEncoded = (url: string, method: string, params: any) =>
    fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${this.getToken()}`,
      },
      body: new URLSearchParams(params),
    })

  fetchByMethodBearer = (url: string, method: string, params: any) =>
    fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(params),
    })

  post = (url: string, params: any, token?: boolean) => {
    if (!token) {
      return this.fetchByMethodEncoded(url, 'POST', params)
    } else {
      return this.fetchByMethodBearer(url, 'POST', params)
    }
  }

  put = (url: string, params: any) => this.fetchByMethod(url, 'PUT', params)

  patch = (url: string, params: any) => this.fetchByMethod(url, 'PATCH', params)

  delete = (url: string) => this.fetchByMethodBearer(url, 'DELETE', {})
}

export const customFetch = new API()
