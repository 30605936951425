import {
  USER_UPDATE_CONNECTED,
  USER_UPDATE_INFOS,
  USER_UPDATE_PAYMENT_METHOD,
} from 'store/constants/user'

export const userState = {
  accountId: 0,
  accountType: 'NANNY',
  email: '',
  fullName: '',
  nannyId: 0,
  connected: false,
  membership_fee: '',
  infos: [],
  paymentMethod: [],
}

export function user(state = userState, action: any) {
  switch (action.type) {
    case USER_UPDATE_INFOS:
      return {
        ...state,
        accountId: action.user.accountId,
        accountType: action.user.accountType,
        email: action.user.email,
        nannyId: action.user.nannyId || null,
        infos: action.user.infos,
        fullName: action.user.fullName,
        connected: true,
      }
    case USER_UPDATE_CONNECTED:
      return {
        ...state,
        connected: action.connected,
      }
    case USER_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
      }
    default:
      return state
  }
}
