import React, { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useScrollPosition } from 'utils/scroll'
import './style.scss'
import { LOGOUT } from 'constants/i18n/en/header.json'
import Avatar from 'component/Avatar'
import DetectOutside from 'utils/hooks/DetectOutside'
import { LOGIN_URL } from 'utils/router/constants'

interface HeaderProps {
  toggleOverlayClass: { (action: 'remove' | 'add'): void }
  toggleMobileSidebarClass: { (action: 'remove' | 'add'): void }
  toggleHtmlClass: { (action: 'remove' | 'add'): void }
}

function Header(props: HeaderProps) {
  const history = useHistory()
  // Initialazing refs
  const dropdownRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)

  // Define dropdown links
  const dropdownLinks = [
    {
      text: LOGOUT,
      onclick: () => {
        localStorage.removeItem('token')
        history.push(LOGIN_URL)
      },
    },
  ]

  // Listen scrolling to stick header
  useScrollPosition(currPos => {
    if (currPos.y < -30) {
      headerRef.current?.classList.add('fixed-header')
    } else {
      headerRef.current?.classList.remove('fixed-header')
    }
  }, 300)

  // Event handlers
  const handleMobileBtnClick = () => {
    props.toggleOverlayClass('add')
    props.toggleMobileSidebarClass('add')
    props.toggleHtmlClass('add')
  }

  const handleToggleBtnClick = () => {
    const body = document.body
    if (body.classList.contains('mini-sidebar')) {
      body.classList.remove('mini-sidebar')
    } else {
      body.classList.add('mini-sidebar')
    }
  }

  const handleDropdownToggleClick = (e: any) => {
    dropdownRef.current?.classList.add('show')
  }

  const handleClickOutsideDropdownMenu = () => {
    dropdownRef.current?.classList.remove('show')
  }

  // Render function
  const renderDropdownLinks = () =>
    dropdownLinks.map((link, index) => (
      <button onClick={link.onclick} key={index} className="dropdown-item">
        {link.text}
      </button>
    ))

  return (
    <div ref={headerRef} className="header">
      <div className="header-left">
        <Link to="/" className="logo logo-small">
          <img
            src="static/images/logo.svg"
            alt="Whizzkid logo"
            width="30"
            height="30"
          />
        </Link>
      </div>
      <button
        onClick={handleToggleBtnClick}
        id="toggle_btn"
        className="toggle-sidebar-button"
      >
        <i className="fas fa-align-left"></i>
      </button>
      <button
        onClick={handleMobileBtnClick}
        className="mobile_btn toggle-sidebar-button"
        id="mobile_btn"
      >
        <i className="fas fa-align-left"></i>
      </button>

      <ul className="nav user-menu">
        <li className="nav-item dropdown">
          <button
            className="dropdown-toggle user-link nav-link toggle-sidebar-button"
            data-toggle="dropdown"
            onClick={handleDropdownToggleClick}
          >
            <span className="user-img">
              <Avatar></Avatar>
            </span>
          </button>
          <DetectOutside
            callback={handleClickOutsideDropdownMenu}
            customRef={dropdownRef}
            className="dropdown-menu dropdown-menu--overwrite dropdown-menu-right"
          >
            {renderDropdownLinks()}
          </DetectOutside>
        </li>
      </ul>
    </div>
  )
}

export default Header
