import { API_ME } from 'api/constants'
import { customFetch } from 'api/helpers'
import Button from 'component/Button'
import InputText from 'component/Inputs/InputText'
import LayoutModal from 'component/Layouts/LayoutModal'
import React, {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USER_UPDATE_INFOS } from 'store/constants/user'
import useForm from 'utils/hooks/useForm'
import { ROLE_NANNY } from 'utils/roles/constants'
import { MODAL_NANNY_UPDATE_FORM } from './constants'
import { MODAL_PARENT_UPDATE_FORM } from './constants'
import { REQUIRED_FIELDS_ARE_MARKED_WITH_AN_ASTERISK } from 'constants/i18n/en/common.json'

interface IModalUserUpdateProps {
  title: string
  buttonText: string
  onRequestClose: () => void
}

const ModalUserUpdate: FunctionComponent<IModalUserUpdateProps> = ({
  title,
  buttonText,
  onRequestClose,
}) => {
  // Initialize local state
  const [isFormValid, setIsFormValid] = useState(true)

  // Initialize store methods
  const dispatch = useDispatch()

  // Retrieving infos from the store
  const user = useSelector((state: IUserObj) => state.user)
  const accountType = user.accountType
  const defaultInputs = user.infos

  // Event handlers
  const handleModalSubmit = () => {
    customFetch
      .put(API_ME, inputs)
      .then((response) => response.json())
      .then(() => {
        user.infos = inputs
        dispatch({ type: USER_UPDATE_INFOS, user })
        onRequestClose()
      })
      .catch((err) => console.log(`Failed user update : ${err}`))
  }

  // Initialize form custom hook
  const { inputs, handleInputChange, handleSubmit } = useForm(
    handleModalSubmit,
    defaultInputs
  )

  //   Render helper methods
  const renderFormColumns = () => {
    let selectedForm =
      accountType === ROLE_NANNY
        ? MODAL_NANNY_UPDATE_FORM
        : MODAL_PARENT_UPDATE_FORM

    return selectedForm.map((formSection, index) => {
      if (formSection.dom === 'input') {
        return (
          <div
            key={index}
            className={`col-12 ${
              formSection.width === 'half' ? 'col-sm-6' : ''
            }`}
          >
            <InputText
              onChange={(
                e: ChangeEvent<HTMLInputElement>,
                setErrorMessage?: Dispatch<SetStateAction<string>>
              ) =>
                handleInputChange(
                  e,
                  setIsFormValid,
                  setErrorMessage,
                  formSection.parent
                )
              }
              pattern={formSection.pattern}
              type={formSection.type}
              disabled={formSection.disabled}
              required={formSection.required === false ? false : true}
              value={
                formSection.parent
                  ? inputs[formSection.parent][formSection.name]
                  : inputs[formSection.name]
              }
              label={formSection.label}
              name={formSection.name}
            />
          </div>
        )
      } else {
        return (
          <div key={index} className="col-12 mt-3">
            <h5 className="form-title">
              <span>{formSection.title}</span>
            </h5>
            {formSection.hint && (
              <p className="form-title-hint">{formSection.hint}</p>
            )}
          </div>
        )
      }
    })
  }

  return (
    <LayoutModal onRequestClose={onRequestClose} title={title}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row form-row">{renderFormColumns()}</div>
        <p className="required-hint">
          {REQUIRED_FIELDS_ARE_MARKED_WITH_AN_ASTERISK}
        </p>
        <Button
          className="btn btn-primary btn-block"
          disabled={!isFormValid}
          text={buttonText}
          submit={true}
        />
      </form>
    </LayoutModal>
  )
}

export default ModalUserUpdate
