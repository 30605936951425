import React from 'react'
import PageHeader from 'component/PageHeader'
import SectionAccount from 'component/SectionAccount'
import SectionContractDetails from 'component/SectionContractDetails'
import SectionPayments from 'component/SectionPayments'
import SectionNannyMissions from 'component/SectionNannyMissions'

interface IDashboardFamilyProps {
  user: IUserState
}

function DashboardFamily(props: IDashboardFamilyProps) {
  const { user } = props

  const parent1 = user.infos.parent1 ? user.infos.parent1.first_name : ''
  const parent2 = user.infos.parent2 ? user.infos.parent2.first_name : ''

  return (
    <>
      <PageHeader>
        <h3 className="page-title">
          Welcome {parent1} {parent2 ? `&  ${parent2} ` : ''}!
        </h3>
      </PageHeader>
      <SectionContractDetails
        role={user.accountType}
        membershipFee={user.infos.membership_fee}
      />
      <SectionAccount user={user} />
      <SectionNannyMissions role={user.accountType} />
      <SectionPayments />
    </>
  )
}

export default DashboardFamily
