import React from 'react'
import {
  STATUS_PENDING,
  STATUS_NOT_PAID,
  STATUS_PAID,
  PAYMENT_ONBOARDING_FEE,
  PAYMENT_MEMBERSHIP_FEE,
  PAYMENT_PLACEMENT_FEE,
  PAYMENT_WHIZZKID_FEE,
  STATUS_ACTIVE,
} from './constants'

export const getPaymentStatus = (status: string) => {
  switch (status) {
    case STATUS_PENDING:
      return <span className="text-success">Pending payment</span>
    case STATUS_NOT_PAID:
      return <span className="text-danger">Not paid</span>
    case STATUS_PAID:
      return <span className="text-success">Paid</span>
    case STATUS_ACTIVE:
      return <span className="text-success">Active</span>
    default:
      return '-'
  }
}

export const getPaymentType = (type: string) => {
  switch (type) {
    case PAYMENT_ONBOARDING_FEE:
      return 'Onboarding fee'
    case PAYMENT_MEMBERSHIP_FEE:
      return 'Membership fee'
    case PAYMENT_PLACEMENT_FEE:
      return 'Placement fee'
    case PAYMENT_WHIZZKID_FEE:
      return 'Whizzkid fee'
    default:
      return '-'
  }
}
